import type { v1, ExtractFromAPI } from "@netgame/openapi";
import { camelCase } from "scule";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import type { GamesPayload } from "./useGamesLoadMore";
import useGamesState from "./useGamesState";
import useGetGameFiltersData from "./useGetGameFiltersData";

type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
type Game = NonNullable<GamesPayload["games"]>[number];

const pickAndCompact = (
	games: Record<string | number, Game & { img: string }> | undefined,
	id: Array<string | number>
) => {
	if (!games) {
		return [];
	}
	return compact(Object.values(pick(games, id)));
};

const useGamesCatalog = (inputGameType?: "favorites" | "recent") => {
	const limitPageName = ref();
	const allGamesData = useState<AllGamesResponse>("allGamesData", () => ({}));
	const menu = useState<GamesPayload["menu"]>("catalog-menu");
	const { all, add } = useGamesState();
	const page = ref<string>("all-games");
	const preload = ref<boolean>(false);
	const gameType = ref(inputGameType);
	const {
		params: { pageName }
	} = useRoute();
	const { isMobile } = useDevice();

	const { data: appInitData } = useAppInitData();

	const fetchData = () => {
		useAsyncFetch({
			path: `/rest/page/issues/${page.value as "all-games"}/`,
			method: "get",
			options: {
				default: () => ({
					payload: {
						games: Array.from({ length: 25 }).map(() => Infinity),
						menu: menu.value
					}
				}),
				transform(data) {
					return {
						...data,
						payload: {
							...data.payload,
							games: add(data?.payload?.games || [])
						}
					};
				}
			},
			fetchOptions: () => ({
				onResponse: ({ response }) => {
					allGamesData.value = {
						...response._data,
						payload: {
							...response._data.payload,
							games: add(response._data?.payload?.games || [])
						}
					};
					menu.value = response._data.payload.menu || [];
					preload.value = false;
				}
			})
		});
	};

	if (!menu?.value?.length) {
		fetchData();
	}

	const load = (link: string) => {
		preload.value = true;
		page.value = link as "all-games";

		if (page.value === "favorites") {
			gameType.value = "favorites";
			return;
		}

		gameType.value = undefined;
		fetchData();
	};

	const { games } = useGetGameFiltersData();
	const {
		public: { limit, loadMoreLimit, mobileLimit, mobileLoadMoreLimit }
	} = useRuntimeConfig();
	const deviceLimit = computed(() => {
		if (isMobile) {
			return mobileLimit;
		}
		return limit;
	});
	const deviceLoadMoreLimit = computed(() => {
		if (isMobile) {
			return mobileLoadMoreLimit;
		}
		return loadMoreLimit;
	});

	const allGames = computed(() => {
		if (!allGamesData.value?.payload?.games?.length) {
			return [];
		}

		return allGamesData.value?.payload?.games?.map((id, index) => {
			if (preload.value) {
				return { id: index, skeleton: true };
			}

			if (typeof id === "number") {
				return all.value![id];
			}

			return all.value![0];
		});
	});

	const filteredGames = computed(() => games.value.reduce((acc, game) => ({ ...acc, [game.id]: game }), {}));
	const favorites = computed(() => pickAndCompact(filteredGames.value, appInitData.value?.favoriteGames || []));
	const recent = computed(() => pickAndCompact(filteredGames.value, appInitData.value?.lastGames || []));

	const gameData = computed(() => {
		if (gameType.value === "favorites") {
			limitPageName.value = favorites.value.length ? "favorites" : "recent";
			return favorites.value.length ? favorites.value : recent.value;
		}

		limitPageName.value = pageName;
		return allGames.value;
	});

	const gamesLimit = useState(camelCase(`${limitPageName.value}-limit`), () => deviceLimit.value);

	const slicedGames = computed(() => {
		if (!gameData.value || !gameData?.value?.length) {
			return [];
		}
		return gameData.value.slice(0, gamesLimit.value);
	});

	const showLoadMoreButton = computed(() => {
		if (!gameData.value || !gameData.value?.length) {
			return false;
		}
		return gamesLimit.value < (gameData.value.length || deviceLimit.value);
	});

	const handleLoadMoreClick = () => {
		gamesLimit.value += deviceLoadMoreLimit.value;
	};

	onUnmounted(() => (gamesLimit.value = deviceLimit.value));

	return {
		menu,
		gamesLimit,
		slicedGames,
		showLoadMoreButton,
		favorites,
		load,
		handleLoadMoreClick
	};
};
export default useGamesCatalog;
